<template>
  <div>

    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>质量等级</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="30">
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
          <el-input placeholder="请输入内容" v-model="querInfo.ql_name" clearable @clear="inputchange">
            <el-button slot="append" icon="el-icon-search" @click="inputchange"></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="addqualitylevelDialog" icon="el-icon-document-add" v-if="is_add">添加</el-button>
        </el-col>
      </el-row>
      <el-table :data="qualitylevellist" border stripe v-loading="loading">
        <el-table-column type="index"></el-table-column>
        <el-table-column label="质量名称" prop="ql_name"></el-table-column>
        <el-table-column label="创建时间" prop="add_date"></el-table-column>
        <el-table-column label="添加人" prop="add_uid"></el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <!-- 编辑 -->
            <el-button type="primary" size="mini"
                       @click="showEditDialog(scope.row.ql_id)" v-if="is_edit"  icon="el-icon-edit"></el-button>
            <!--删除-->
            <el-button type="danger" size="mini"
                       @click="removeWareById(scope.row.ql_id)" v-if="is_del" icon="el-icon-delete-solid"></el-button>

          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="querInfo.page"
          :page-sizes="[3,5,10,15]"
          :page-size="querInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </el-card>
    <!-- 添加质量等级对话框-->
    <el-dialog
        title="添加质量等级" :visible.sync="addqualitylevelDialogVisible" width="50%" @close="addqualitylevelDialogClose">
      <!--    添加质量等级的表单    -->
      <el-form :model="addqualitylevelForm" :rules="addqualitylevelFormRules" ref="addqualitylevelFormRef" label-width="150px">
        <el-form-item label="质量等级名称：" prop="ql_name">
          <el-input v-model="addqualitylevelForm.ql_name"></el-input>
        </el-form-item>



      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addqualitylevelDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addqualitylevel" :loading="addloading">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑质量等级对话框-->
    <el-dialog
        title="编辑质量等级" :visible.sync="editqualitylevelDialogVisible" width="50%" @close="editqualitylevelDialogClose">
      <!--    添加质量等级的表单    -->
      <el-form :model="editqualitylevelForm" :rules="editqualitylevelFormRules" ref="editqualitylevelFormRef" label-width="150px">
        <el-form-item label="质量等级名称：" prop="ql_name">
          <el-input v-model="editqualitylevelForm.ql_name"></el-input>
        </el-form-item>



      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editqualitylevelDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editqualitylevel" :loading="editloading">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return{
      addloading:false,
      editloading:false,
      // qualitylevellist:[
      //   {ql_name:'国标',add_date:'2020.10.28',add_uid:"123"}
      // ],
      //计量单位的添加
      qualitylevellist:[],
      //分页
      querInfo: {
        page: 1,
        limit: 10,
        ql_name:''
      },
      total:0,
      //页面权限
      customerlevelJurisdiction:[],
      //添加按钮的展示与隐藏
      is_add:false,
      is_edit:false,
      is_del:false,
      //表格加载
      loading:true,
      //添加计量单位
      addqualitylevelForm:{
        ql_name:''
      },
      //编辑计量单位
      editqualitylevelForm:{},
      //控制添加对话框的显示和隐藏
      addqualitylevelDialogVisible:false,
      //控制编辑对话框的显示和隐藏
      editqualitylevelDialogVisible:false,
      //添加分类表单的验证规则对象
      addqualitylevelFormRules: {
        ql_name: [
          {required: true, message: '请输入质量等级的名称', trigger: 'blur'},
        ]
      },
      //添加分类表单的验证规则对象
      editqualitylevelFormRules: {
        ql_name: [
          {required: true, message: '请输入质量等级的名称', trigger: 'blur'},
        ]
      },
    }
  },
  created() {
    this.getqualitylevelList()
    this.getqualityleveJurisdiction()
  },
  methods: {
    //获取权限接口
    async getqualityleveJurisdiction(){
      this.loading=true
      let str = window.sessionStorage.getItem('activePath');
      let activePath = str.split("/").join("");
      const { data : res } = await this.$http.get('menu/getMenuChildrenList', {params:{m_path : activePath}})
      if (res.code!=200)return  this.$message.error(res.msg)
      this.qualityleveJurisdiction=res.data
      this.qualityleveJurisdiction.forEach((item)=>{
        if (item.m_path=='add'){
          this.is_add=true
        }else if (item.m_path=='edit'){
          this.is_edit=true
        }else if(item.m_path=='del'){
          this.is_del=true
        }
      })
    },
    async getqualitylevelList() {
      this.loading=true
      const {data: res} = await this.$http.get('qualityleve/getList',
          {params: this.querInfo})
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值给catelist
      this.qualitylevellist = res.data.data
      //把数据赋值给total
      this.total = res.data.total
      this.loading=false
    },
    //监听pagesize改变
    handleSizeChange(newSize) {
      this.querInfo.limit = newSize
      this.getqualitylevelList()
    },
    //监听pagenum 改变
    handleCurrentChange(newPage) {
      this.querInfo.page = newPage
      this.getqualitylevelList()
    },
    inputchange(){
      this.querInfo.page=1
      this.getqualitylevelList()
    },
    // 添加计量单位
    addqualitylevelDialog(){
      this.addqualitylevelDialogVisible=true
    },
    //监听添加对话框的关闭事件
    addqualitylevelDialogClose(){
      this.addqualitylevelForm.ql_name=''
      this.$refs.addqualitylevelFormRef.resetFields()
    },
    editqualitylevelDialogClose(){
      this.editqualitylevelForm.ql_name=''
      this.$refs.editqualitylevelFormRef.resetFields()
    },
    //提交
    async addqualitylevel(){
      this.addloading=true
      const {data:res} = await this.$http.post('qualityleve/add',this.addqualitylevelForm)
      this.addloading=false
      if (res.code!=200)return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.addqualitylevelDialogVisible=false
      this.getqualitylevelList()
    },
    //编辑  查找当前id
    async showEditDialog(id){
      this.editqualitylevelDialogVisible=true
      const {data:res} = await this.$http.get(`qualityleve/find/${id}`)
      if (res.code!=200)return this.$message.error(res.msg)
      this.editqualitylevelForm=res.data
    },
    //提交编辑 qualitylevel/edit
    async editqualitylevel(){
      this.editloading=true
      const {data:res} = await this.$http.post('qualityleve/edit',this.editqualitylevelForm)
      this.editloading=false
      if (res.code!=200) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.editqualitylevelDialogVisible=false
      this.getqualitylevelList()
    },
    //删除 qualitylevel/del/12
    async removeWareById(id){
      const confirmResult = await this.$confirm(
          '此操作将永久删除该质量等级, 是否继续?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除')
      }
      const {data:res} = await this.$http.post(`qualityleve/del/${id}`)
      if (res.code!=200)return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.getqualitylevelList()

    }
  }
}
</script>
